/* App.svelte generated by Svelte v3.18.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_style,
	space
} from "svelte/internal";

import { onMount } from "svelte";
import photoData from "./photoData.json";

function add_css() {
	var style = element("style");
	style.id = "svelte-1co7047-style";
	style.textContent = "#main.svelte-1co7047{height:100%;display:flex;color:white;font-size:12px}map.svelte-1co7047{flex:1 1 auto;height:100%}nav.svelte-1co7047{position:relative;flex:0 0 1008px;height:100%;overflow:auto;background:#222}.thumbnail-image.svelte-1co7047{display:inline-block;vertical-align:top;box-sizing:border-box;border:1px solid black;background:gray;object-fit:cover;cursor:zoom-in;opacity:0.5}.thumbnail-image.svelte-1co7047:hover{opacity:1}figure.svelte-1co7047{position:absolute;top:0;height:100%;width:100%;display:flex;justify-content:center;align-items:center;background:#222}#closeup-image.svelte-1co7047{max-height:100%;max-width:100%;cursor:zoom-out}cite.svelte-1co7047{position:absolute;bottom:0;left:0;padding:8px}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (5:2) {#each photosInView as photo}
function create_each_block(ctx) {
	let img;
	let img_src_value;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[5](/*photo*/ ctx[8], ...args);
	}

	return {
		c() {
			img = element("img");
			attr(img, "class", "thumbnail-image svelte-1co7047");
			set_style(img, "width", /*thumbnailSize*/ ctx[2] + "px");
			set_style(img, "height", /*thumbnailSize*/ ctx[2] + "px");
			if (img.src !== (img_src_value = "./photos/" + (/*thumbnailSize*/ ctx[2] > 191 ? "full" : "thumb") + "/" + /*photo*/ ctx[8].properties.url)) attr(img, "src", img_src_value);
		},
		m(target, anchor) {
			insert(target, img, anchor);
			dispose = listen(img, "click", click_handler);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*thumbnailSize*/ 4) {
				set_style(img, "width", /*thumbnailSize*/ ctx[2] + "px");
			}

			if (dirty & /*thumbnailSize*/ 4) {
				set_style(img, "height", /*thumbnailSize*/ ctx[2] + "px");
			}

			if (dirty & /*thumbnailSize, photosInView*/ 5 && img.src !== (img_src_value = "./photos/" + (/*thumbnailSize*/ ctx[2] > 191 ? "full" : "thumb") + "/" + /*photo*/ ctx[8].properties.url)) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			dispose();
		}
	};
}

// (8:2) {#if selectedPhoto}
function create_if_block(ctx) {
	let figure;
	let img;
	let img_src_value;
	let dispose;

	return {
		c() {
			figure = element("figure");
			img = element("img");
			if (img.src !== (img_src_value = "./photos/full/" + /*selectedPhoto*/ ctx[3])) attr(img, "src", img_src_value);
			attr(img, "id", "closeup-image");
			attr(img, "class", "svelte-1co7047");
			attr(figure, "class", "svelte-1co7047");
		},
		m(target, anchor) {
			insert(target, figure, anchor);
			append(figure, img);
			dispose = listen(figure, "click", /*click_handler_1*/ ctx[6]);
		},
		p(ctx, dirty) {
			if (dirty & /*selectedPhoto*/ 8 && img.src !== (img_src_value = "./photos/full/" + /*selectedPhoto*/ ctx[3])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(figure);
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let map;
	let t0;
	let nav;
	let t1;
	let t2;
	let cite;
	let each_value = /*photosInView*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block = /*selectedPhoto*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			map = element("map");
			t0 = space();
			nav = element("nav");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			cite = element("cite");

			cite.innerHTML = `
		built with <a href="https://cesium.com/cesiumjs/">cesium</a> and <a href="https://svelte.dev/">svelte</a>`;

			attr(map, "id", "cesium-root");
			attr(map, "class", "svelte-1co7047");
			attr(nav, "class", "svelte-1co7047");
			attr(cite, "class", "svelte-1co7047");
			attr(div, "id", "main");
			attr(div, "class", "svelte-1co7047");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, map);
			append(div, t0);
			append(div, nav);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(nav, null);
			}

			append(nav, t1);
			if (if_block) if_block.m(nav, null);
			/*nav_binding*/ ctx[7](nav);
			append(div, t2);
			append(div, cite);
		},
		p(ctx, [dirty]) {
			if (dirty & /*thumbnailSize, photosInView, selectedPhoto*/ 13) {
				each_value = /*photosInView*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(nav, t1);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*selectedPhoto*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(nav, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (if_block) if_block.d();
			/*nav_binding*/ ctx[7](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const photosInView = [];
	let thumbnailContainer = {};
	let thumbnailSize = 64;
	let selectedPhoto = null;
	let viewer = {};
	photoData.features.sort((a, b) => (a.properties.date > b.properties.date) - 0.5);
	Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4NmZjNDk1ZS02Mjc1LTQ5YzgtODdhMy02MGMxMzA4MDg2MjIiLCJpZCI6MjIxOTcsInNjb3BlcyI6WyJhc3IiXSwiaWF0IjoxNTgyMzkyMDMxfQ.g2xsKoYIO5TwP2arlDG2YseVce2vhsuxfaWoWcEouNo"; // steal my bus pass I dare you

	onMount(() => {
		$$invalidate(4, viewer = new Cesium.Viewer("cesium-root",
		{
				// heightmap
				terrainProvider: Cesium.createWorldTerrain(),
				// turn off lots of stuff we don't need
				skyAtmosphere: false,
				animation: false,
				baseLayerPicker: false,
				fullscreenButton: false,
				homeButton: false,
				navigationHelpButton: false,
				sceneModePicker: false,
				timeline: false,
				geocoder: false,
				selectionIndicator: false,
				infoBox: false
			}));

		// draw the points on the map
		viewer.entities.suspendEvents();

		viewer.dataSources.add(Cesium.GeoJsonDataSource.load(photoData, { markerSize: 0 }));

		for (const entity of viewer.dataSources.get(0).entities.values) {
			entity.point = {
				scaleByDistance: new Cesium.NearFarScalar(0, 1, 10000, 0.25),
				pixelSize: 20,
				color: Cesium.Color.RED,
				outlineWidth: 1,
				outlineColor: Cesium.Color.BLACK
			};
		}

		viewer.entities.resumeEvents();

		// look at japan
		viewer.scene.camera.setView({
			destination: Cesium.Cartesian3.fromDegrees(135, 35, 5000000), // long
			// lat
			// height (m)
			
		});

		// show the pic when we click on a point
		let prevSelectedEntity = { point: {} };

		const now = Cesium.JulianDate.now();
		const eventHelper = new Cesium.EventHelper();

		eventHelper.add(viewer.selectedEntityChanged, () => {
			prevSelectedEntity.point.color = Cesium.Color.BLACK;
			prevSelectedEntity.point.outlineColor = Cesium.Color.WHITE;

			if (viewer.selectedEntity && viewer.selectedEntity.point) {
				$$invalidate(3, selectedPhoto = viewer.selectedEntity.properties.url.getValue(now));
				$$invalidate(4, viewer.selectedEntity.point.color = Cesium.Color.YELLOW, viewer);
				prevSelectedEntity = viewer.selectedEntity;
			} else {
				$$invalidate(3, selectedPhoto = "");
			}
		});

		// recalc ambient pics & thumbnail size when we drag the map
		eventHelper.add(viewer.scene.camera.moveEnd, () => {
			const viewRectangle = viewer.scene.camera.computeViewRectangle(viewer.scene.globe.ellipsoid);
			const latView = [viewRectangle.west * 180 / Math.PI, viewRectangle.east * 180 / Math.PI];
			const lonView = [viewRectangle.south * 180 / Math.PI, viewRectangle.north * 180 / Math.PI];
			$$invalidate(0, photosInView.length = 0, photosInView);

			for (const photo of photoData.features) {
				if (photo.geometry.coordinates[0] > latView[0] & photo.geometry.coordinates[0] < latView[1] & photo.geometry.coordinates[1] > lonView[0] & photo.geometry.coordinates[1] < lonView[1]) {
					photosInView.push(photo);
				}
			}

			let numThumbnailsInRow = 0;

			do {
				numThumbnailsInRow++;
				$$invalidate(2, thumbnailSize = Math.floor(thumbnailContainer.clientWidth / numThumbnailsInRow));
			} while (Math.floor(thumbnailContainer.clientWidth / thumbnailSize) * Math.floor(thumbnailContainer.clientHeight / thumbnailSize) < photosInView.length);
		});

		// don't zoom in on double click
		viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
	});

	const click_handler = photo => $$invalidate(3, selectedPhoto = photo.properties.url);

	const click_handler_1 = () => {
		$$invalidate(3, selectedPhoto = "");
		$$invalidate(4, viewer.selectedEntity = null, viewer);
	};

	function nav_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			$$invalidate(1, thumbnailContainer = $$value);
		});
	}

	return [
		photosInView,
		thumbnailContainer,
		thumbnailSize,
		selectedPhoto,
		viewer,
		click_handler,
		click_handler_1,
		nav_binding
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1co7047-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;